













































































































































































































































































































































































































import { Manager } from '@/api/interfaces/manager'
import { Company } from '@/api/interfaces/company'
import { newManager } from '@/components/molecules/select-search/store/manager'
import { defineComponent } from '@vue/composition-api'
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import cancelSaveDialog from '@/mixins/cancelSaveDialog.js'
import { mapFields } from 'vuex-map-fields'
import { RawLocation } from 'vue-router'
import { isReadonly } from '@/composables/UserGrant'

export default defineComponent({
  setup () {
    const readonly = () => isReadonly()
    return {
      readonly,
    }
  },
  name: 'CompanyEdit',
  mixins: [cancelSaveDialog],
  components: {
    CancelUpdateDialog: () =>
    import('../../../components/cancel-update-dialog.vue'),
    CreateEditManager: () =>
    import('../../../components/molecules/dialog/createEditManager.vue'),
    ScrollTop: () => import('@/components/base/ScrollTop.vue'),
    TopBar: () => import('@/components/organisms/o-top-bar.vue'),
    SelectCompanies: () =>
    import('@/components/molecules/select-search/company.vue'),
    PhoneNumber: () => import('@/components/molecules/input/phone-number.vue'),
    SelectCompanyAccountType: () => import('@/components/molecules/select/companyAccountType.vue'),
    SelectArea: () => import('@/components/molecules/select/area.vue'),
  },
  data () {
    return {
      title: this.$t('company.title'),
      managerEdit: {},
      manager: (newManager() as unknown) as Manager,
      clientTypesList: ['Professionnels', 'Particuliers'],
      companyTypesList: ['SA', 'SARL', 'EURL', 'SAS'],
      professionList: ['Gestionnaire'],
      createEditManagerDialog: false,
      headersManagers: [
        { value: 'firstName', text: this.$t('form.firstName') },
        { value: 'lastName', text: this.$t('form.lastName') },
        { value: 'phoneNumber', text: this.$t('form.phone') },
        { value: 'secondPhoneNumber', text: this.$t('form.secondPhone') },
        { value: 'email', text: this.$t('form.mail') },
        { value: 'profession', text: this.$t('form.profession') },
        {
          text: this.$t('company.list.headers.actions'),
          value: 'actions',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapState('companyForm', {
      company: 'company',
      isCompanySubmitted: 'isSubmitted',
      isFormChanged: 'isChanged',
    }),
    ...mapFields('companyForm', [
      'company.id',
      'company.name',
      'company.nameComplementary',
      'company.city',
      'company.managers',
      'company.address',
      'company.clientType',
      'company.area',
      'company.type',
      'company.zipCode',
      'company.phoneNumber',
      'company.secondPhoneNumber',
      'company.email',
      'company.website',
      'company.siret',
      'company.businessIntroducer',
      'company.commentary',
      'company.parentCompany',
      'company.accountType',
    ]),
    isEdit () {
      return !!this.$route.params.idCompany
    },
    ...mapState('area', {
      listAreas: 'list',
    }),
    ...mapGetters('navigation', {
      getLastSavedRoute: 'getLastSavedRoute',
    }),
  },
  async created () {
    await this.init()
  },
  watch: {
    $route () {
      this.init()
    },
  },
  methods: {
    async init () {
      this.reset()
      this.resetManagers()
      const idCompany = this.$route.params.idCompany
      if (idCompany && this.isEdit) {
        await this.loadCompanyById(idCompany)
        await this.loadManagers(this.company)
      }
    },
    ...mapActions('managerList', {
      loadManagers: 'load',
      resetManagers: 'reset',
      post: 'post',
    }),
    ...mapMutations('companyForm', {
      addManager: 'addManager',
    }),
    ...mapActions('companyForm', {
      loadCompanyById: 'loadById',
      save: 'save',
      reset: 'reset',
      setMainManager: 'setMainManager',
    }),
    ...mapActions('user', {
      sendSuccessMessage: 'sendSuccessMessage',
    }),

    async onSubmit () {
      const isValidatedForm = await (this as any).isValidatedForm(
        this.$refs.validationObserver,
      )
      if (isValidatedForm) {
        await this.save()
        this.sendSuccessMessage('company.form.saved')
        this.returnPrevious()
      }
    },
    cancel () {
      this.returnPrevious()
    },
    returnPrevious () {
      if (this.getLastSavedRoute) {
        this.$router.push(this.getLastSavedRoute as RawLocation)
      } else {
        this.$router.push({ name: 'Company List' })
      }
    },
    close () {
      this.createEditManagerDialog = false
      this.managerEdit = {}
    },
    addNewManager (manager: Manager) {
      this.addManager(manager)
    },
    replaceManager (manager: Manager, managerToReplace: Manager) {
      if ((this.company as Company).managers) {
        ((this.company as Company).managers as Array<Manager>).find(
          (x: Manager, key: number) => {
            if (JSON.stringify(x) === JSON.stringify(managerToReplace)) {
              this.$set(
              (this.company as Company)?.managers as Array<Manager>,
              key,
              manager,
              )
              return x
            }
          },
        )
      }
    },
  },
})
